<template>
  <div class="pa-0">
    <!-- <Navbar /> -->
    <div class="text-center mb-4 fn24-weight7" style="color: #1a347f">
      Notifications
    </div>
    <v-tabs
      v-model="tabHead"
      active-class="active"
      color="#06204B"
      centered
      fixed-tabs
      :height="65"
    >
      <v-tabs-slider color="rgba(255, 255, 255, 0)"></v-tabs-slider>
      <v-tab
        v-for="(item, index) in itemtabHeads"
        :key="index"
        width="180"
        class="px-0"
        style="position: relative"
        @click="selectTab(index)"
      >
        <v-card
          style="background: #c4c4c4; height: 58px; width: 187px"
          :style="index ? 'position: absolute; left: -8px;' : 'z-index: 1;'"
          class="rounded-b-0 rounded-t-lg white--text fn22-weight7"
          :id="`b${index}`"
          :class="!index ? 'tab-color' : ''"
        >
          <img :src="item.image" class="my-2" width="40" height="40" />
          {{ item.point }}
          <span class="font-weight-thin fn12 text-lowercase">{{
            item.typePoint
          }}</span>
        </v-card>
      </v-tab>
    </v-tabs>

    <v-divider class="mb-2" />
    <v-card
      style="background: #f0f3fa"
      elevation="0"
      height="45"
      class="rounded-lg text-center"
    >
      <v-row align="center" justify="center" class="my-4">
        <v-col cols="12">
          <span class="fn12" style="color: #4f4f4f"
            >1,000 points expires on 31 Dec 2021</span
          >
        </v-col>
      </v-row>
    </v-card>
    <v-divider class="my-2" />

    <v-tabs-items v-model="tabHead">
      <v-tab-item v-for="(item, index) in itemtabHeads" :key="index">
        <v-tabs
          v-model="item.tab"
          active-class="active"
          color="#06204B"
          centered
          fixed-tabs
        >
          <v-tab v-for="(item, index) in item.items" :key="index" width="160">
            <span
              style="color: #1a347f"
              class="text-capitalize mt-3 fn12-weight7 text-center"
            >
              {{ item.name }}
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="item.tab">
          <v-tab-item v-for="(item, index) in item.items" :key="index">
            <v-card
              flat
              v-for="(object, index) in item.objects"
              :key="index"
              class="mt-2"
            >
              <v-row no-gutters align="center" justify="center">
                <v-col cols="9">
                  <div class="text-left fn12">
                    {{ object.name }}
                  </div>
                  <div class="fn10" style="color: #4f4f4f">
                    {{ object.date }}
                  </div>
                  <div class="fn10" style="color: #4f4f4f">
                    {{ object.time }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <img src="@/assets/BI.png" width="24" />
                </v-col>
                <v-col cols="1" class="text-right fn12-weight7">
                  {{ object.points }}
                </v-col>
                <v-col cols="12" class="">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
// import Navbar from "@/components/nav";
export default {
  components: {
    draggable,
    Loading,
    // Navbar,
  },
  data() {
    return {
      tab: null,
      tabHead: null,
      itemtabHeads: [
        {
          image: require("@/assets/BI.png"),
          point: "0",
          typePoint: "points",
          nameTab: "BI",
          tab: null,
          items: [
            {
              name: "Earned",
              objects: [
                {
                  name: "E-Learning: Online Marketing 101-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "20",
                },
                {
                  name: "Games: Pick a Pig-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "150",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
              ],
            },
            {
              name: "Used",
            },
          ],
        },
        {
          image: require("@/assets/icon/CE.png"),
          point: "0",
          typePoint: "points",
          nameTab: "CE",
          tab: null,
          items: [
            {
              name: "Approved",
              objects: [
                {
                  name: "E-Learning: Online Marketing 101-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "20",
                },
                {
                  name: "Games: Pick a Pig-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "150",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
                {
                  name: "Event: FLEXcitement 2021-Activity special FUN! | BI Points 3942",
                  date: "2021-11-11 13:23",
                  time: "Valid til 2022-12-31",
                  points: "200",
                },
              ],
            },
            {
              name: "Pending",
            },
          ],
        },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  // created() {
  //   this.user = JSON.parse(
  //     Decode.decode(localStorage.getItem("userBiData"))
  //   );
  //   this.getAllbrand();
  // },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditBrand");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async DeleteBrand(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brands/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbrand");
    },

    selectTab(index) {
      const addTab = `b${index ? "1" : "0"}`;
      const removeTab = `b${index ? "0" : "1"}`;

      document.getElementById(removeTab).classList.remove("tab-color");
      document.getElementById(addTab).classList.add("tab-color");
    },
  },
};
</script>

<style scoped lang="scss">
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.bg-card {
  background: #06204b;
}
.v-slide-group__prev {
  display: none !important;
}
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin-right: 0px !important;
}
.tab-color {
  background-color: #06204b !important;
}
</style>
